import { chakra, Flex, Heading, Text } from "@chakra-ui/react";
import Layout, { WithPageLayout } from "components/Layout";
import Head from "next/head";
import { ReactNode } from "react";

const NotFound: WithPageLayout = () => {
  return (
    <div>
      <Head>
        <title>GrowthDeputy - 404</title>
      </Head>

      <chakra.section minH="620px" color="#0E101A">
        <Flex
          pt="153px"
          flexDirection="column"
          alignItems="center"
          justifyContent={"space-between"}
          textAlign="center"
        >
          <Text variant="paragraph-1" fontWeight="bold">
            Page not found
          </Text>
          <Heading variant="h2" mb="24px">
            Oops!
          </Heading>
          <Text variant="paragraph-2" maxW="551px">
            The page you were looking for doesn’t seem to exist. You may have
            mistyped the address or the page may have moved.
          </Text>
        </Flex>
      </chakra.section>
    </div>
  );
};

export default NotFound;

NotFound.getLayout = function getLayout(page: ReactNode) {
  return <Layout>{page}</Layout>;
};
